import { createAsync, useLocation, useParams } from '@solidjs/router';
import { BreadcrumbItem, Breadcrumbs, Heading, Container, Page, Section, Prose } from '@troon/ui';
import { ErrorBoundary, Show, Suspense } from 'solid-js';
import { Meta, Title } from '@solidjs/meta';
import { NoHydration } from 'solid-js/web';
import { NotFoundContent } from '../partials/404';
import { getDocument } from './api/documents/[docType]/[slug]';

export default function TopLevelDocumentPage() {
	const params = useParams<{ document: string }>();
	const loc = useLocation();
	const data = createAsync(() => getDocument('document', params.document), {
		deferStream: true,
	});

	return (
		<ErrorBoundary fallback={<NotFoundContent />}>
			<NoHydration>
				<Suspense>
					<Show when={data()}>
						{(data) => (
							<>
								<>
									<Title>{data().title} | Troon</Title>
									<Meta name="description" content={data().title} />
								</>
								<Container class="pt-4">
									<Suspense>
										<Show when={data()}>
											<Breadcrumbs>
												<BreadcrumbItem href={loc.pathname}>{data()?.title}</BreadcrumbItem>
											</Breadcrumbs>
										</Show>
									</Suspense>
								</Container>
								<Container size="small" class="flex flex-col gap-8">
									<Page>
										<Section>
											<Heading as="h1">{data()?.title}</Heading>
											<Prose innerHTML={data().content} />
										</Section>
									</Page>
								</Container>
							</>
						)}
					</Show>
				</Suspense>
			</NoHydration>
		</ErrorBoundary>
	);
}
